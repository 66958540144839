import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { useProcessOzoneData } from "./useProcessOzoneData";
import { defaultGraphHeight, defaultGraphWidth, TITLE_OZONE } from "../../../../helpers/graphConstants";
import { API_WEATHER, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { WeatherOzoneLineGraphMetadata } from "../../../../helpers/graphsMetadata";

export const OzoneGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(LocationQueryParams.defaultOzoneParams, API_WEATHER, DATA_SERVICE_URL);
  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const { outputData, outputOptions } = useProcessOzoneData(graphData, selectedInterval);
  const graphDisplay = (
    <Line data={outputData} options={outputOptions} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  const description = [
    "Ground level ozone is formed primarily from photochemical reactions between two major classes of air" +
      " pollutants, volatile organic compounds (VOC) and nitrogen oxides (NOx). These reactions have traditionally been viewed as depending upon the presence of heat and sunlight, resulting in higher ambient ozone concentrations in summer months. ",
    "When inhaled, it reacts chemically with many biological molecules in the respiratory tract, leading to a number of adverse health effects. Your personal ozone is recorded so that you can better understand the impact it has on your life (e.g.  exercise frequency, exercise performance, congestion, headache, mood, etc.). ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_OZONE}
        title={TITLE_OZONE}
        calendarProps={graphObject}
        id={WeatherOzoneLineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_OZONE} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
