import React from "react";
import {
  NetflixPieChartMetadata,
  NetflixTimeStackedBarMetadata,
  NetflixTotalBarMetadata,
} from "../../../../helpers/graphsMetadata";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";

export const NetflixPage = (): JSX.Element => {
  const consumeGraphs = [
    NetflixTimeStackedBarMetadata.id,
    NetflixTotalBarMetadata.id,
    NetflixPieChartMetadata.id,
    NetflixTotalBarMetadata.id,
  ];

  return <GraphListWrapper graphNameList={consumeGraphs} />;
};
