import React from "react";
import { Bubble } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_POLLEN, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { defaultGraphHeight, defaultGraphWidth, TITLE_POLLEN } from "../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { WeatherPollenLineGraphMetadata } from "../../../../helpers/graphsMetadata";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { useProcessPollenDataProcessor } from "./useProcessPollenDataProcessor";

export const PollenGraph = () => {
  const graphObject = useGraphSetup(LocationQueryParams.defaultWeatherParams, API_POLLEN, DATA_SERVICE_URL);
  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessPollenDataProcessor(graphData);
  const graphDisplay = <Bubble data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_POLLEN}
        title={TITLE_POLLEN}
        calendarProps={graphObject}
        id={WeatherPollenLineGraphMetadata.id}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_POLLEN} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
