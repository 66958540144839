import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { useProcessHumidityData } from "./useProcessHumidityData";
import { defaultGraphHeight, defaultGraphWidth, TITLE_HUMIDITY } from "../../../../helpers/graphConstants";
import { API_WEATHER, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { WeatherHumidityLineGraphMetadata } from "../../../../helpers/graphsMetadata";

export const HumidityGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(LocationQueryParams.defaultHumidityParams, API_WEATHER, DATA_SERVICE_URL);
  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const { outputData, outputOptions } = useProcessHumidityData(graphData, selectedInterval);
  const graphDisplay = (
    <Line data={outputData} options={outputOptions} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  // TODO: links https://en.wikipedia.org/wiki/Humidity
  const description = [
    " Humidity is the concentration of water vapour present in the air. Water vapor, the gaseous state of water, is" +
      " generally invisible to the human eye.  ",
    "Humidity indicates the likelihood for precipitation, dew, or fog to be present.  ",
    "Your personal humidity is recorded so that you can better understand the impact it has on your life (e.g.  outdoor time, exercise frequency, exercise performance, symptoms, headaches, mood, etc.). ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_HUMIDITY}
        title={TITLE_HUMIDITY}
        calendarProps={graphObject}
        id={WeatherHumidityLineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_HUMIDITY} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
