import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { useProcessTemperatureData } from "./useProcessTemperatureData";
import { defaultGraphHeight, defaultGraphWidth, TITLE_TEMPERATURE } from "../../../../helpers/graphConstants";
import { API_WEATHER, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { WeatherTemperatureLineGraphMetadata } from "../../../../helpers/graphsMetadata";
import { UserSettingsContext } from "../../../../types/general/UserSettingsContext";

export const TemperatureGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(LocationQueryParams.defaultTemperatureParams, API_WEATHER, DATA_SERVICE_URL);
  const { userSettings } = useContext(UserSettingsContext);
  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const { outputData, outputOptions } = useProcessTemperatureData(
    graphData,
    selectedInterval,
    userSettings.settings.general?.units.temperature,
  );
  const graphDisplay = (
    <Line data={outputData} options={outputOptions} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  // TODO: (spk) links
  // https://en.wikipedia.org/wiki/Temperature
  // https://en.wikipedia.org/wiki/Thermal_energy
  // https://en.wikipedia.org/wiki/Heat

  const description = [
    " Temperature is a physical quantity that expresses hot and cold. It is the manifestation of thermal energy," +
      " present in all matter, which is the source of the occurrence of heat, a flow of energy, when a body is in contact with another that is colder or hotter. ",
    "Your personal outdoor temperature is recorded so that you can better understand the impact it has on your life (e.g.  outdoor time, exercise frequency, exercise performance, symptoms, eating, mood, etc.).",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_TEMPERATURE}
        title={TITLE_TEMPERATURE}
        calendarProps={graphObject}
        id={WeatherTemperatureLineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_TEMPERATURE} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
