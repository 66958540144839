import React from "react";
import { AllGraphsInterface, GraphInfoInterface } from "../../helpers/context/contextVariables";
import { HeartRateOxygenLineGraph } from "../graphs/body/HeartRateOxygenLineGraph/HeartRateOxygenLineGraph";
import {
  AirElementsMetaData,
  AQIMetadata,
  DailyStepsGraphMetadata,
  ExerciseLatestMetadata,
  ExerciseLineGraphMetadata,
  FacebookLineGraphMetadata,
  HeartRateHistogramGraphMetadata,
  HeartRateOxygenLineGraphMetadata,
  MenstrualLineGraphMetadata,
  MovementHistogramMetadata,
  MovementPieGraphMetadata,
  MovementStackedBarMetadata,
  NetflixPieChartMetadata,
  NetflixTimeStackedBarMetadata,
  NetflixTotalBarMetadata,
  PlaceBarGraphMetadata,
  PlacePieGraphMetadata,
  PMMetadata,
  RestingHeartRateHistogramGraphMetadata,
  RestingHeartRateLineGraphMetadata,
  ShoppingCategoriesGraphMetadata,
  ShoppingHistogramGraphMetadata,
  ShoppingSpendGraphMetadata,
  SleepBarGraphMetadata,
  SleepHistogramGraphMetadata,
  SleepTimeGraphMetadata,
  StepsHistogramMetadata,
  TagsGraphMetadata,
  WeatherCloudCoverVisibilityGraphMetaData,
  WeatherHumidityLineGraphMetadata,
  WeatherOzoneLineGraphMetadata,
  WeatherPollenLineGraphMetadata,
  WeatherPressureLineGraphMetadata,
  WeatherTemperatureLineGraphMetadata,
  WeatherWindLineGraphMetadata,
  WebActivityBarGraphMetadata,
  WebActivityHistogramGraphMetadata,
  WeightBMILineGraphMetadata,
  WeightHistogramGraphMetadata,
  YoutubeCountsBarGraphMetadata,
} from "../../helpers/graphsMetadata";
import { FacebookTimeChart } from "../graphs/activity/FacebookGraphs/FacebookTimeChart";
import { WebActivityGraph } from "../graphs/activity/WebActivityGraph/WebActivityGraph";
import { WebActivityHistogram } from "../graphs/activity/WebActivityGraph/WebActivityHistogram";
import { SleepBarGraph } from "../graphs/activity/SleepGraph/SleepBarGraph";
import { SleepTime } from "../graphs/activity/SleepGraph/StartTime/SleepTime";
import { RestingHeartRateLineGraph } from "../graphs/body/RestingHeartRateLineGraph/RestingHeartRateLineGraph";
import { TagsGraph } from "../graphs/events/TagGraph/TagsGraph";
import { DailyStepsGraph } from "../graphs/activity/StepsGraphs/DailyStepsGraph";
import { StepsHistogram } from "../graphs/activity/StepsGraphs/StepsHistogram";
import { AQIGraph } from "../graphs/location/EnvironmentAirGraph/AQIGraph";
import { PMGraph } from "../graphs/location/EnvironmentAirGraph/PMGraph";
import { ElementsGraphs } from "../graphs/location/EnvironmentAirGraph/ElementsGraph";
import { MovementPieGraph } from "../graphs/activity/MovementGraphs/MovementPieGraph";
import { MovementStackedBarGraph } from "../graphs/activity/MovementGraphs/MovementStackedBarGraph";
import { PlaceBarGraph } from "../graphs/location/Places/PlaceBarGraph";
import { PlacePieGraph } from "../graphs/location/Places/PlacePieGraph";
import { ShoppingSpendGraph } from "../graphs/activity/ShoppingGraphs/ShoppingSpendGraph";
import { ShoppingCategoriesGraph } from "../graphs/activity/ShoppingGraphs/ShoppingCategoriesGraph";
import { YoutubeCountsBarGraph } from "../graphs/activity/YoutubeGraph/YoutubeCountsBarGraph";
import { NetflixTotalBar } from "../graphs/activity/NetflixGraph/NetflixTotalBar";
import { NetflixPieChart } from "../graphs/activity/NetflixGraph/NetflixPieChart";
import { NetflixTimeStackedBar } from "../graphs/activity/NetflixGraph/NetflixTimeStackedBar";
import { WeightBMILineGraph } from "../graphs/body/WeightBMILineGraph/WeightBmiLineGraph";
import { MenstrualCycleGraph } from "../graphs/body/MenstrualFertilityLineGraph/MenstrualCycleGraph";
import { ExerciseGraph } from "../graphs/activity/ExerciseGraph/ExerciseGraph";
import { LatestExercise } from "../graphs/activity/ExerciseGraph/LatestExercise";
import { TemperatureGraph } from "../graphs/location/TemperatureGraph/TemperatureGraph";
import { SleepHistogramGraph } from "../graphs/activity/SleepHistogramGraph/SleepHistogramGraph";
import { HeartRateHistogramGraph } from "../graphs/body/HeartRateHistogramGraph/HeartRateHistogramGraph";
import { RestingHeartRateHistogramGraph } from "../graphs/body/RestingHeartRateHistogramGraph/RestingHeartRateHistogramGraph";
import { MovementHistogramGraph } from "../graphs/activity/MovementGraphs/MovementHistogramGraph/MovementHistogramGraph";
import { WeightHistogramGraph } from "../graphs/body/WeightHistogramGraph/WeightHistogramGraph";
import { ShoppingHistogramGraph } from "../graphs/activity/ShoppingGraphs/ShoppingHistogramGraph/ShoppingHistogramGraph";
import { HumidityGraph } from "../graphs/location/Humidity/HumidityGraph";
import { OzoneGraph } from "../graphs/location/Ozone/OzoneGraph";
import { VisibilityGraph } from "../graphs/location/VisibilityGraph/VisibilityGraph";
import { PressureGraph } from "../graphs/location/PressureGraph/PressureGraph";
import { WindGraph } from "../graphs/location/WindGraph/WindGraph";
import { PollenGraph } from "../graphs/location/PollenGraph/PollenGraph";

const HeartRateOxygenLineRenderSettings: GraphInfoInterface = {
  graph: <HeartRateOxygenLineGraph />,
  render: true,
  metadataField: HeartRateOxygenLineGraphMetadata.metadataField,
};

const HeartRateHistogramRenderSettings: GraphInfoInterface = {
  graph: <HeartRateHistogramGraph />,
  render: true,
  metadataField: HeartRateHistogramGraphMetadata.metadataField,
};

const FacebookInteractionsRenderSettings: GraphInfoInterface = {
  graph: <FacebookTimeChart />,
  render: false,
  metadataField: FacebookLineGraphMetadata.metadataField,
};

const WebActivityRenderSettings: GraphInfoInterface = {
  graph: <WebActivityGraph />,
  render: true,
  metadataField: WebActivityBarGraphMetadata.metadataField,
};

const WebActivityHistogramRenderSettings: GraphInfoInterface = {
  graph: <WebActivityHistogram />,
  render: true,
  metadataField: WebActivityHistogramGraphMetadata.metadataField,
};

const SleepBarRenderSettings: GraphInfoInterface = {
  graph: <SleepBarGraph />,
  render: true,
  metadataField: SleepBarGraphMetadata.metadataField,
};

const SleepTimeRenderSettings: GraphInfoInterface = {
  graph: <SleepTime />,
  render: true,
  metadataField: SleepTimeGraphMetadata.metadataField,
};

const SleepHistogramRenderSettings: GraphInfoInterface = {
  graph: <SleepHistogramGraph />,
  render: true,
  metadataField: SleepHistogramGraphMetadata.metadataField,
};

const RestingHeartRateLineRenderSettings: GraphInfoInterface = {
  graph: <RestingHeartRateLineGraph />,
  render: true,
  metadataField: RestingHeartRateLineGraphMetadata.metadataField,
};

const RestingHeartRateHistogramRenderSettings: GraphInfoInterface = {
  graph: <RestingHeartRateHistogramGraph />,
  render: true,
  metadataField: RestingHeartRateHistogramGraphMetadata.metadataField,
};

const TagsBubbleRenderSettings: GraphInfoInterface = {
  graph: <TagsGraph />,
  render: true,
  metadataField: TagsGraphMetadata.metadataField,
};

const DailyStepsRenderSettings: GraphInfoInterface = {
  graph: <DailyStepsGraph />,
  render: true,
  metadataField: DailyStepsGraphMetadata.metadataField,
};

const StepsHistogramRenderSettings: GraphInfoInterface = {
  graph: <StepsHistogram />,
  render: true,
  metadataField: StepsHistogramMetadata.metadataField,
};

const AQILineRenderSettings: GraphInfoInterface = {
  graph: <AQIGraph />,
  render: true,
  metadataField: AQIMetadata.metadataField,
};

const PMLineRenderSettings: GraphInfoInterface = {
  graph: <PMGraph />,
  render: true,
  metadataField: PMMetadata.metadataField,
};

const AirElementsRenderSettings: GraphInfoInterface = {
  graph: <ElementsGraphs />,
  render: true,
  metadataField: AirElementsMetaData.metadataField,
};

const MovementPieRenderSettings: GraphInfoInterface = {
  graph: <MovementPieGraph />,
  render: false,
  metadataField: MovementPieGraphMetadata.metadataField,
};

const MovementStackedBarGraphRenderSettings: GraphInfoInterface = {
  graph: <MovementStackedBarGraph />,
  render: false,
  metadataField: MovementStackedBarMetadata.metadataField,
};

const MovementHistogramGraphRenderSettings: GraphInfoInterface = {
  graph: <MovementHistogramGraph />,
  render: false,
  metadataField: MovementHistogramMetadata.metadataField,
};

const PlaceBarGraphRenderSettings: GraphInfoInterface = {
  graph: <PlaceBarGraph />,
  render: false,
  metadataField: PlaceBarGraphMetadata.metadataField,
};

const PlacePieGraphRenderSettings: GraphInfoInterface = {
  graph: <PlacePieGraph />,
  render: false,
  metadataField: PlacePieGraphMetadata.metadataField,
};

const ShoppingSpendGraphRenderSettings: GraphInfoInterface = {
  graph: <ShoppingSpendGraph />,
  render: false,
  metadataField: ShoppingSpendGraphMetadata.metadataField,
};

const ShoppingCategoriesGraphRenderSettings: GraphInfoInterface = {
  graph: <ShoppingCategoriesGraph />,
  render: false,
  metadataField: ShoppingCategoriesGraphMetadata.metadataField,
};

const ShoppingHistogramRenderSettings: GraphInfoInterface = {
  graph: <ShoppingHistogramGraph />,
  render: false,
  metadataField: ShoppingHistogramGraphMetadata.metadataField,
};

const YoutubeCountsBarGraphRenderSettings: GraphInfoInterface = {
  graph: <YoutubeCountsBarGraph />,
  render: true,
  metadataField: YoutubeCountsBarGraphMetadata.metadataField,
};

const NetflixTotalBarGraphRenderSettings: GraphInfoInterface = {
  graph: <NetflixTotalBar />,
  render: true,
  metadataField: NetflixTotalBarMetadata.metadataField,
};

const NetflixPieChartRenderSettings: GraphInfoInterface = {
  graph: <NetflixPieChart />,
  render: true,
  metadataField: NetflixPieChartMetadata.metadataField,
};

const NetflixTimeStackedBarRenderSettings: GraphInfoInterface = {
  graph: <NetflixTimeStackedBar />,
  render: true,
  metadataField: NetflixTimeStackedBarMetadata.metadataField,
};

const WeightBMILineGraphRenderSettings: GraphInfoInterface = {
  graph: <WeightBMILineGraph />,
  render: true,
  metadataField: WeightBMILineGraphMetadata.metadataField,
};

const WeightHistogramGraphRenderSettings: GraphInfoInterface = {
  graph: <WeightHistogramGraph />,
  render: true,
  metadataField: WeightHistogramGraphMetadata.metadataField,
};

const MenstrualGraphRenderSettings: GraphInfoInterface = {
  graph: <MenstrualCycleGraph />,
  render: true,
  metadataField: MenstrualLineGraphMetadata.metadataField,
};

const ExerciseGraphRenderSettings: GraphInfoInterface = {
  graph: <ExerciseGraph />,
  render: false,
  metadataField: ExerciseLineGraphMetadata.metadataField,
};
const ExerciseLatestGraphRenderSettings: GraphInfoInterface = {
  graph: <LatestExercise />,
  render: true,
  metadataField: ExerciseLatestMetadata.metadataField,
};

const WeatherTemperatureGraphRenderSettings: GraphInfoInterface = {
  graph: <TemperatureGraph />,
  render: true,
  metadataField: WeatherTemperatureLineGraphMetadata.metadataField,
};

const WeatherHumidityLineGraphRenderSettings: GraphInfoInterface = {
  graph: <HumidityGraph />,
  render: true,
  metadataField: WeatherHumidityLineGraphMetadata.metadataField,
};

const WeatherOzoneLineGraphRenderSettings: GraphInfoInterface = {
  graph: <OzoneGraph />,
  render: true,
  metadataField: WeatherOzoneLineGraphMetadata.metadataField,
};

const WeatherCloudCoverVisibilityGraphRenderSettings: GraphInfoInterface = {
  graph: <VisibilityGraph />,
  render: true,
  metadataField: WeatherCloudCoverVisibilityGraphMetaData.metadataField,
};

const WeatherPressureGraphRenderSettings: GraphInfoInterface = {
  graph: <PressureGraph />,
  render: true,
  metadataField: WeatherPressureLineGraphMetadata.metadataField,
};

const WeatherWindGraphRenderSettings: GraphInfoInterface = {
  graph: <WindGraph />,
  render: true,
  metadataField: WeatherWindLineGraphMetadata.metadataField,
};

const WeatherPollenGraphRenderSettings: GraphInfoInterface = {
  graph: <PollenGraph />,
  render: true,
  metadataField: WeatherPollenLineGraphMetadata.metadataField,
};

export const allGraphs: AllGraphsInterface = {
  [HeartRateOxygenLineGraphMetadata.id]: HeartRateOxygenLineRenderSettings,
  [RestingHeartRateLineGraphMetadata.id]: RestingHeartRateLineRenderSettings,
  [RestingHeartRateHistogramGraphMetadata.id]: RestingHeartRateHistogramRenderSettings,
  [HeartRateHistogramGraphMetadata.id]: HeartRateHistogramRenderSettings,
  [FacebookLineGraphMetadata.id]: FacebookInteractionsRenderSettings,
  [WebActivityBarGraphMetadata.id]: WebActivityRenderSettings,
  [WebActivityHistogramGraphMetadata.id]: WebActivityHistogramRenderSettings,
  [SleepBarGraphMetadata.id]: SleepBarRenderSettings,
  [SleepTimeGraphMetadata.id]: SleepTimeRenderSettings,
  [SleepHistogramGraphMetadata.id]: SleepHistogramRenderSettings,
  [TagsGraphMetadata.id]: TagsBubbleRenderSettings,
  [DailyStepsGraphMetadata.id]: DailyStepsRenderSettings,
  [StepsHistogramMetadata.id]: StepsHistogramRenderSettings,
  [AQIMetadata.id]: AQILineRenderSettings,
  [PMMetadata.id]: PMLineRenderSettings,
  [AirElementsMetaData.id]: AirElementsRenderSettings,
  [MovementStackedBarMetadata.id]: MovementStackedBarGraphRenderSettings,
  [MovementPieGraphMetadata.id]: MovementPieRenderSettings,
  [MovementHistogramMetadata.id]: MovementHistogramGraphRenderSettings,
  [PlaceBarGraphMetadata.id]: PlaceBarGraphRenderSettings,
  [PlacePieGraphMetadata.id]: PlacePieGraphRenderSettings,
  [ShoppingSpendGraphMetadata.id]: ShoppingSpendGraphRenderSettings,
  [ShoppingCategoriesGraphMetadata.id]: ShoppingCategoriesGraphRenderSettings,
  [ShoppingHistogramGraphMetadata.id]: ShoppingHistogramRenderSettings,
  [YoutubeCountsBarGraphMetadata.id]: YoutubeCountsBarGraphRenderSettings,
  [NetflixTotalBarMetadata.id]: NetflixTotalBarGraphRenderSettings,
  [NetflixPieChartMetadata.id]: NetflixPieChartRenderSettings,
  [NetflixTimeStackedBarMetadata.id]: NetflixTimeStackedBarRenderSettings,
  [WeightBMILineGraphMetadata.id]: WeightBMILineGraphRenderSettings,
  [WeightHistogramGraphMetadata.id]: WeightHistogramGraphRenderSettings,
  [MenstrualLineGraphMetadata.id]: MenstrualGraphRenderSettings,
  [ExerciseLineGraphMetadata.id]: ExerciseGraphRenderSettings,
  [ExerciseLatestMetadata.id]: ExerciseLatestGraphRenderSettings,
  [WeatherTemperatureLineGraphMetadata.id]: WeatherTemperatureGraphRenderSettings,
  [WeatherHumidityLineGraphMetadata.id]: WeatherHumidityLineGraphRenderSettings,
  [WeatherOzoneLineGraphMetadata.id]: WeatherOzoneLineGraphRenderSettings,
  [WeatherCloudCoverVisibilityGraphMetaData.id]: WeatherCloudCoverVisibilityGraphRenderSettings,
  [WeatherPressureLineGraphMetadata.id]: WeatherPressureGraphRenderSettings,
  [WeatherWindLineGraphMetadata.id]: WeatherWindGraphRenderSettings,
  [WeatherPollenLineGraphMetadata.id]: WeatherPollenGraphRenderSettings,
};
